import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { MESSAGE, forGotSchema } from '../schemas/Validation';
import * as API from '../api/index';
import { toast } from 'react-toastify';
import { useState } from 'react';
import OTPInput from 'react-otp-input';
import NewPasswordSet from '../components/NewPasswordSet';
const initialValues = {
  email: '',
};
const ForgotPassword = () => {
  const [isEmail, setIsEmail] = useState(0);
  const [otp, setOtp] = useState('');
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [timer, setTimer] = useState(60);

  const { values, errors, handleBlur, handleChange, handleSubmit, touched } = useFormik({
    initialValues: initialValues,
    validationSchema: forGotSchema,
    onSubmit: (values) => {
      loginSubmit(values);
    },
  });

  const loginSubmit = async (value) => {
    try {
      const response = await API.user_forgotPass(value);
      if (response.data.data.success === 1) {
        setIsEmail(1);
        setIsResendDisabled(true);
        setTimer(60);
        MESSAGE(response.data.data.msg, 1);
      } else if (response.data.data.status == 0) {
        MESSAGE(response.data.data.msg, 0);
      } else {
        toast.error(response.data.data.msg);
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const verifyOtp = async () => {
    try {
      const reqObj = {
        email: values.email,
        otp: otp,
      };
      const response = await API.otp_varification(reqObj);
      if (response.data.data.success === 1) {
        setIsEmail(3);
        MESSAGE(response.data.data.msg, 1);
      } else {
        MESSAGE(response.data.data.msg);
      }
    } catch (error) {}
  };

  const resendOtp = async () => {
    try {
      const reqObj = {
        email: values.email,
      };
      const response = await API.resend_otp(reqObj);
      if (response.data.data.success === 1) {
        setIsResendDisabled(true);
        setTimer(60);
        toast(response.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          type: 'success',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
      if (response.data.data.success === 1) {
        MESSAGE(response.data.data.msg, 1);
      }
    } catch (error) {}
  };

  const handleResendOtp = () => {
    if (!isResendDisabled) {
      resendOtp();
    }
  };

  useEffect(() => {
    let interval;
    if (isResendDisabled && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
    } else if (timer === 0) {
      setIsResendDisabled(false);
    }

    return () => clearInterval(interval);
  }, [timer, isResendDisabled]);

  return (
    <>
      <div class='ms_genres_wrapper'>
        <div class='registersec'>
          <div class='row'>
            <div class='ms_register_img'>
              <img src='assets/images/register_img.png' alt='' class='img-fluid' />
            </div>
            <div class='ms_register_form'>
              {isEmail === 0 ? (
                <>
                  <h2>Forgot Password</h2>
                  <form onSubmit={handleSubmit}>
                    <div class='form-group'>
                      <input type='text' placeholder='Enter Your Email' class='form-control' name='email' value={values.email} onChange={handleChange} onBlur={handleBlur} />
                      <span class='form_icon'>
                        <i class='fa_icon form-envelope' aria-hidden='true'></i>
                      </span>
                      {touched.email && errors.email ? (
                        <>
                          <p className='errorMess'>{errors.email}</p>
                        </>
                      ) : null}
                    </div>
                    <button class='ms_btn' onClick={loginSubmit}>
                      Submit
                    </button>
                  </form>
                </>
              ) : isEmail === 1 ? (
                <>
                  <h2>Email Verification</h2>
                  <p>{values.email}</p>
                  <div className='otpInput d-flex justify-content-center'>
                    <OTPInput value={otp} onChange={setOtp} numInputs={6} renderSeparator={<span>-</span>} renderInput={(props) => <input {...props} />} />
                  </div>
                  <button className='ms_btn' onClick={verifyOtp}>
                    Verify OTP
                  </button>
                  <p>
                    <span
                      className={`ms_modal resend ${isResendDisabled ? 'disabled' : ''}`}
                      onClick={handleResendOtp}
                      style={{ cursor: isResendDisabled ? 'not-allowed' : 'pointer' }}
                    >
                      Resend OTP {isResendDisabled && `in ${timer}s`}
                    </span>
                  </p>
                </>
              ) : (
                <NewPasswordSet email={values.email} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
