import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import * as API from '../api/index';
import { IMG } from '../api/constant';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { toast } from 'react-toastify';
import ALERT_BEEP from '../assets/songs/alert-beep.mp3';

const MessagePlacePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [songDetails, setSongDetails] = useState('');
  const [isPlay, setIsPlay] = useState(false);
  const [isPlaym, setIsPlaym] = useState(false);
  const [isPlaye, setIsPlaye] = useState(false);
  const [userMargi, setUserMargi] = useState('0');
  const [combineData, setCombineData] = useState('');
  const [mainSong, setMainSong] = useState(false);
  const [loader, setLoader] = useState(false);
  const [voiceMessage, setVoiceMessage] = useState('');
  const [voiceMessageMiddil, setVoiceMessageMiddil] = useState('');
  const [voiceMessageEnd, setVoiceMessageEnd] = useState('');

  // ? Start record
  const recorderControls = useAudioRecorder();
  const addAudioElement = (blob) => {
    setIsPlay(!isPlay);
    const url = URL.createObjectURL(blob);
    const audio = document.createElement('audio');
    audio.src = url;
    audio.controls = true;
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      var base64data = reader.result;
      setVoiceMessage(base64data);
    };
    const audioTag = document.querySelector('#recordAudioss');

    audioTag.appendChild(audio);
  };

  // ? meddile record
  const recorderControlM = useAudioRecorder();
  const addAudioElementM = (blob) => {
    setIsPlaym(!isPlaym);
    const url = URL.createObjectURL(blob);
    const audio = document.createElement('audio');
    audio.src = url;
    audio.controls = true;
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      var base64data = reader.result;
      setVoiceMessageMiddil(base64data);
    };
    const audioTag = document.querySelector('#recordAudiossM');
    audioTag.appendChild(audio);
  };

  // ? End record
  const recorderControlss = useAudioRecorder();
  const addAudioElements = (blob) => {
    setIsPlaye(!isPlaye);
    const url = URL.createObjectURL(blob);
    const audio = document.createElement('audio');
    audio.src = url;
    audio.controls = true;
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      var base64data = reader.result;
      setVoiceMessageEnd(base64data);
    };
    const audioTag = document.querySelector('#recordAudio');
    audioTag.appendChild(audio);
  };

  const songProcess = async () => {
    const header = localStorage.getItem('_tokenCode');
    setLoader(true);
    try {
      const reqObj = {
        template: location.state.tamId,
        intro: voiceMessage,
        outro: voiceMessageEnd,
        isRecord: userMargi,
        ...(userMargi == 0 && { middle: voiceMessageMiddil }),
      };

      const response = await API.audiomarge(reqObj, header);
      setCombineData(response.data.data);
      if (response.data.success === 1) {
        setLoader(false);
        setMainSong(true);
      } else if (response.data.success === 0) {
        toast.error(response?.data?.msg);
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const reloadAudio = () => {
    setIsPlay(!isPlay);
    setVoiceMessage(false);
    let parentElement = document.getElementById('recordAudioss');
    parentElement.innerHTML = '';
  };

  const reloadAudiom = () => {
    setIsPlaym(!isPlaym);
    setVoiceMessageMiddil(false);
    let middleElement = document.getElementById('recordAudiossM');
    middleElement.innerHTML = '';
  };

  const reloadAudioe = () => {
    setIsPlaye(!isPlaye);
    setVoiceMessageEnd(false);
    let lastElement = document.getElementById('recordAudio');
    lastElement.innerHTML = '';
  };

  const combineDelete = async () => {
    const header = localStorage.getItem('_tokenCode');
    try {
      const response = await API.audiomarge_delete(combineData.id, header);
      if (response.data.success === 1) {
        setMainSong(false);
      }
    } catch (error) {
      console.error('Error deleting audio merge:', error);
    }
  };

  const usermargitype = (e) => {
    setUserMargi(e.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const data = JSON.parse(localStorage.getItem('__template'));
    if (!data || !location.state) {
      navigate('/song-list');
    } else {
      setSongDetails(data);
    }
  }, []);

  const useRecordingAutoStop = (recorderControl, useRecorderControlM) => {
    useEffect(() => {
      if (
        recorderControl.isRecording &&
        (!useRecorderControlM ? songDetails?.intro_outro_length !== 0 : true) &&
        (songDetails?.temtype === 1 ? songDetails?.duration !== 0 : true)
      ) {
        const songTime = useRecorderControlM ? Number(songDetails?.duration) + 0.2 : Number(songDetails?.intro_outro_length) + 0.2;

        const timer = setTimeout(() => {
          recorderControl.stopRecording();
          toast.error('Stopped a recording and set a maximum song limit.');
        }, songTime * 1000);

        const beepTimer = setTimeout(() => {
          const beep = new Audio(ALERT_BEEP);
          beep.play();
        }, songTime * 1000);

        return () => {
          clearTimeout(timer);
          clearTimeout(beepTimer);
        };
      }
    }, [recorderControl.isRecording, songDetails?.intro_outro_length, songDetails?.duration, useRecorderControlM]);
  };

  useRecordingAutoStop(recorderControls, false);
  useRecordingAutoStop(recorderControlM, true);
  useRecordingAutoStop(recorderControlss, false);

  return (
    <>
      <div className='row justify-content-center ms_genre'>
        <div className='col-md-12'>
          <div className='ms_profile_box messPlacement'>
            <div className='ms_pro_form'>
              <div className='countDowns'>
                <div className='row'>
                  <div className='col-md-2'>
                    <Link className='ms_btn' to='/song-details'>
                      Back
                    </Link>
                  </div>
                </div>
                <h3 className='headingC'>
                  <i className='bi bi-music-note-beamed'></i> {JSON.parse(localStorage.getItem('__selectedSongName'))}
                </h3>
                <p>You can choose to record your voice at the begining and end of the song and also use the prerecorded middle message or record your own</p>
              </div>

              {songDetails?.temtype === 1 && (
                <div className='row justify-content-center typeofPlase'>
                  <div className='col-md-5'>
                    <select className='form-control' onChange={usermargitype}>
                      <option value='0'>Record My Own</option>
                      <option value='1'>Use Prerecorded</option>
                    </select>
                  </div>
                </div>
              )}

              <div className='row align-items-center justify-content-center'>
                <div className='col-md-4'>
                  <h4 className='typeLabel'>Intro</h4>
                  <p className='lablePara'>{`Record your own personal greeting${
                    songDetails?.intro_outro_length > 0 ? ` up to ${songDetails?.intro_outro_length} seconds long` : ''
                  }`}</p>
                  <div className='form-groupd'>
                    <div className={voiceMessage ? 'd-none' : ''}>
                      <AudioRecorder
                        recorderControls={recorderControls}
                        onRecordingComplete={addAudioElement}
                        audioTrackConstraints={{
                          noiseSuppression: true,
                          echoCancellation: true,
                        }}
                        downloadOnSavePress={false}
                        downloadFileExtension='mp3'
                      />
                    </div>

                    <div id='recordAudioss'></div>

                    <div className={isPlay ? 'd-flex justify-content-center' : 'd-none'}>
                      <button className='recmp' id='removeSourceAttribute' onClick={reloadAudio}>
                        <i className='bi bi-arrow-clockwise'></i>
                      </button>
                    </div>
                  </div>
                </div>
                {songDetails?.temtype === 1 ? (
                  <div className={userMargi === '0' ? 'col-md-4' : 'd-none'}>
                    <h4 className='typeLabel'>Middle </h4>
                    <p className='lablePara'>You can keep the pre-recorded message or record your very own message or story</p>
                    <div className='form-groupd'>
                      <div className={voiceMessageMiddil ? 'd-none' : ''}>
                        <AudioRecorder
                          recorderControls={recorderControlM}
                          onRecordingComplete={addAudioElementM}
                          audioTrackConstraints={{
                            noiseSuppression: true,
                            echoCancellation: true,
                          }}
                          downloadOnSavePress={false}
                          downloadFileExtension='mp3'
                        />
                      </div>
                      <p className='lablePara' style={{ marginTop: 10, marginBottom: 0 }}>
                        Maximum Length {songDetails?.duration > 60 ? `${Math.floor(songDetails?.duration / 60)} minutes` : `${songDetails?.duration} seconds`}
                      </p>
                      <div id='recordAudiossM'></div>
                      <div className={isPlaym ? 'd-flex justify-content-center' : 'd-none'}>
                        <button className='recmp' id='removeSourceAttribute' onClick={reloadAudiom}>
                          <i className='bi bi-arrow-clockwise'></i>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                <div className='col-md-4'>
                  <h4 className='typeLabel'>Outro</h4>
                  <p className='lablePara'>{`Your parting words ${songDetails?.intro_outro_length > 0 ? ` up to ${songDetails?.intro_outro_length} seconds long` : ''}`}</p>
                  <div className='form-groups'>
                    <div className={voiceMessageEnd ? 'd-none' : ''}>
                      <AudioRecorder
                        recorderControls={recorderControlss}
                        onRecordingComplete={addAudioElements}
                        audioTrackConstraints={{
                          noiseSuppression: true,
                          echoCancellation: true,
                        }}
                        downloadOnSavePress={false}
                        downloadFileExtension='mp4'
                      />
                    </div>
                    <div id='recordAudio'></div>
                    <div className={isPlaye ? 'd-flex justify-content-center' : 'd-none'}>
                      <button className='recmp' id='removeSourceAttribute' onClick={reloadAudioe}>
                        <i className='bi bi-arrow-clockwise'></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {voiceMessage || voiceMessageMiddil || voiceMessageEnd ? (
                <div className='pro-form-btn text-center marger_top15'>
                  {loader ? (
                    <button className='ms_btn process'>Processing...</button>
                  ) : (
                    <button className='ms_btn' onClick={songProcess}>
                      Process Start
                    </button>
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
      {mainSong ? (
        <>
          <div className='row'>
            <div className='col-md-9'>
              <AudioPlayer autoPlay={false} src={IMG + combineData.combined} onPlay={(e) => console.log('onPlay')} />
            </div>
            <div className='col-md-3'>
              <div className='rightSide'>
                <Link state={{ mainId: combineData.id }} className='ms_btn confrimorder' to='/order-details'>
                  Confirm
                </Link>
                <span className='trashIcon' onClick={combineDelete}>
                  <i className='bi bi-trash3-fill'></i>
                </span>
              </div>
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default MessagePlacePage;
