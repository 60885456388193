import React from 'react';
import AddToCart from './AddToCart';

const Cart = ({ cartItem, totalAmount, rootApiData, isLogin }) => {
  return (
    <div className='cartpage'>
      <AddToCart cartItem={cartItem} rootApiData={rootApiData} totalAmount={totalAmount} isLogin={isLogin} />
    </div>
  );
};

export default Cart;
