import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as API from '../api/index';
import { toast } from 'react-toastify';
import { MESSAGE } from '../schemas/Validation';
import Loader from '../components/Loader';

const EditProfile = ({ formData, handalerChanges, allCityData, allCountryData, allStateData, getUserData, cityData, stateData, countryData, userDataGetById, loading }) => {
  const [errorName, setErrorName] = useState('');
  const [errorCity, setErrorCity] = useState('');
  const [errorState, setErrorState] = useState('');
  const [errorCountry, setErrorCountry] = useState('');
  const [loader, setLoader] = useState(false);

  const validateForm = () => {
    let valid = true;
    if (!formData.name) {
      setErrorName('Name field is required');
      valid = false;
    } else {
      setErrorName('');
    }
    if (!formData.city) {
      setErrorCity('City field is required');
      valid = false;
    } else {
      setErrorCity('');
    }
    if (!formData.state) {
      setErrorState('State field is required');
      valid = false;
    } else {
      setErrorState('');
    }
    if (!formData.country) {
      setErrorCountry('Country field is required');
      valid = false;
    } else {
      setErrorCountry('');
    }
    return valid;
  };

  const userdataUpdate = async () => {
    if (!validateForm()) return;

    setLoader(true);
    const header = localStorage.getItem('_tokenCode');
    const reqObj = {
      name: formData.name,
      email: formData.email,
      city: typeof cityData === 'number' ? cityData : formData.city,
      state: typeof stateData === 'number' ? stateData : formData.state,
      country: typeof countryData === 'number' ? countryData : formData.country,
      address: formData.address,
      address2: formData.address2,
      phone: formData.phone,
      id: localStorage.getItem('__userId'),
    };
    try {
      const response = await API.getuser_update(reqObj, header);
      if (response.data.data.success === 1) {
        userDataGetById();
        toast.success(response.data.data.msg);
      } else {
        toast.error(response.data.data.msg);
      }
    } catch (error) {
      toast.error('An error occurred while updating user data.');
    } finally {
      setLoader(false);
    }
  };

  const fetchCountryData = async () => {
    const header = localStorage.getItem('_tokenCode');
    try {
      // Fetch country data logic here
    } catch (error) {
      toast.error('An error occurred while fetching country data.');
    }
  };

  useEffect(() => {
    fetchCountryData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    handalerChanges(e);

    if (name === 'name' && value) {
      setErrorName('');
    }
    if (name === 'city' && value) {
      setErrorCity('');
    }
    if (name === 'state' && value) {
      setErrorState('');
    }
    if (name === 'country' && value) {
      setErrorCountry('');
    }
  };

  return (
    <>
      {loading ? (
        <Loader customHeight={false} />
      ) : (
        <div className='ms_profile_box'>
          <div className='ms_pro_form'>
            <div className='form-group'>
              <label>
                Name <span className='requed'>*</span>
              </label>
              <input type='text' placeholder='First Name' name='name' value={formData.name} onChange={handleInputChange} className='form-control position-relative' />
              {errorName && <p className='text-danger error-msg'>{errorName}</p>}
            </div>
            <div className='form-group'>
              <label>
                Email <span className='requed'>*</span>
              </label>
              <input type='Email' placeholder='Email' className='form-control position-relative' value={formData.email} name='email' onChange={handalerChanges} disabled />
            </div>
            <div className='form-group'>
              <label>Phone Number (optional)</label>
              <input type='text' placeholder='Phone No' className='form-control position-relative' value={formData.phone} name='phone' onChange={handalerChanges} />
            </div>
            <div className='form-group'>
              <label>Address (optional)</label>
              <input type='text' placeholder='Address' value={formData.address} onChange={handalerChanges} name='address' className='form-control position-relative' />
            </div>
            <div className='form-group'>
              <label>Address1 (optional)</label>
              <input type='text' placeholder='Address1' value={formData.address2} onChange={handalerChanges} name='address2' className='form-control position-relative' />
            </div>
            <div className='form-group'>
              <label>
                Country <span className='requed'>*</span>
              </label>
              <select value={formData.country} onChange={handleInputChange} name='country' className='form-control position-relative'>
                <option value='' selected>
                  --- Select ---
                </option>
                {allCountryData?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              {errorCountry && <p className='text-danger error-msg'>{errorCountry}</p>}
            </div>
            <div className='form-group'>
              <label>
                State <span className='requed'>*</span>
              </label>
              <select onChange={handleInputChange} value={formData.state} name='state' className='form-control position-relative'>
                <option value='' selected>
                  --- Select ---
                </option>
                {allStateData?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              {errorState && <p className='text-danger error-msg'>{errorState}</p>}
            </div>
            <div className='form-group'>
              <label>
                City <span className='requed'>*</span>
              </label>
              <select value={formData.city} onChange={handleInputChange} name='city' className='form-control position-relative'>
                <option value='' selected>
                  --- Select ---
                </option>
                {allCityData?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              {errorCity && <p className='text-danger error-msg'>{errorCity}</p>}
            </div>
            <div className='pro-form-btn text-center marger_top15'>
              <button onClick={userdataUpdate} className='ms_btn' disabled={loader}>
                {loader ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

EditProfile.propTypes = {
  formData: PropTypes.object.isRequired,
  handalerChanges: PropTypes.func.isRequired,
  allCityData: PropTypes.array.isRequired,
  allCountryData: PropTypes.array.isRequired,
  allStateData: PropTypes.array.isRequired,
  getUserData: PropTypes.func.isRequired,
  cityData: PropTypes.any,
  stateData: PropTypes.any,
  countryData: PropTypes.any,
  userDataGetById: PropTypes.func.isRequired,
};

export default EditProfile;
