import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from '../assets/images/wlogo.png';
import * as API from '../api/index';
import { IMG } from '../api/constant';

const Sidebar = ({ isOpen, sidebarOpen, isLogin, cartItem, trackData }) => {
  let token = JSON.parse(localStorage.getItem('isLogin'));
  const handleLinkClick = () => {
    if (window.innerWidth <= 768) {
      sidebarOpen();
    }
  };

  return (
    <>
      <div className={isOpen ? 'ms_sidemenu_wrapper open_menu' : 'ms_sidemenu_wrapper'}>
        <div className='ms_nav_close' onClick={sidebarOpen}>
          <i className='fa fa-angle-right' aria-hidden='true'></i>
        </div>
        <div className='list-icon' onClick={sidebarOpen}>
          {!isOpen ? <i class='bi bi-list'></i> : <i class='bi bi-x'></i>}
        </div>
        <div className='ms_sidemenu_inner'>
          <div className='ms_logo_inner'>
            <div className='ms_logo'>
              <Link to='/'>
                <img src={IMG + trackData?.logo} alt='' className='img-fluid w-75' />
              </Link>
            </div>
            <div className='ms_logo_open'>
              <Link to='/' className='w-100 h-100 p-0'>
                <img src={IMG + trackData?.logo} alt='' className='img-fluid d-flex' style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
              </Link>
            </div>
          </div>
          <div className='ms_nav_wrapper'>
            <ul>
              <li>
                <NavLink to='/' title='Home Page' onClick={handleLinkClick}>
                  <span className='nav_icon'>
                    <span className='icon icon_discover'></span>
                  </span>
                  <span className='nav_text'>Home</span>
                </NavLink>
              </li>
              <li>
                <NavLink to='/song-list' title='Songs' onClick={handleLinkClick}>
                  <span className='nav_icon'>
                    <i className='bi bi-music-note'></i>
                  </span>
                  <span className='nav_text'>Songs</span>
                </NavLink>
              </li>
              <li>
                <NavLink to='/wrapin-music' title='Wrap In Music' onClick={handleLinkClick}>
                  <span className='nav_icon'>
                    <i className='bi bi-music-note-list'></i>
                  </span>
                  <span className='nav_text'>Wrap In Music</span>
                </NavLink>
              </li>
              <li>
                <NavLink to='/contact-us' title='Contact Us' onClick={handleLinkClick}>
                  <span className='nav_icon'>
                    <i className='bi bi-telephone'></i>
                  </span>
                  <span className='nav_text'>Contact Us</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={token ? '/my-account' : '/login'} title={token ? 'My Account' : 'Register/Login'} yclassName='nav-link'>
                  <span className='nav_icon'>
                    <i className='bi bi-person-circle'></i>
                  </span>
                  <span className='nav_text'>{token ? 'My Account' : 'Login'}</span>
                </NavLink>
              </li>
              <li>
                <div className='floating'>
                  <Link to='/cart' title='Cart' onClick={handleLinkClick}>
                    <i className='bi bi-cart3'></i> <span className='nav_text'>Cart</span>
                  </Link>
                  {token ? (
                    <>
                      <span className='count'>{cartItem.length === 0 ? 0 : cartItem.length}</span>
                    </>
                  ) : (
                    <>
                      <span className='count'>0</span>
                    </>
                  )}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
