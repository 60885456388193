import React from 'react';

const Privacy = () => {
  return (
    <div class='privacy-container'>
      <h1 class='main-title'>Privacy Policy</h1>
      <p class='last-modified'>LAST MODIFIED: 10 July 2024</p>

      <p class='intro'>
        Wrapped In Music takes your privacy very seriously. This Privacy Policy explains how Wrapped In Music collects, uses, and discloses information, and your choices for
        managing your information preferences.
      </p>

      <p class='description'>
        This Privacy Policy describes Wrapped In Music’s data practices associated with our website (wrappedinmusic.com/) and Wrapped In Music services ("Services"), and the
        choices that Wrapped In Music provides in connection with our collection and use of your information. This Privacy Policy is intended for website publisher customers
        ("Publishers"), website merchant customers ("Merchants") and individual users of websites and apps. For Publishers and Merchants, this Policy explains how Wrapped In Music
        may collect, use and disclose information associated with your company and with your company’s websites and apps that use Wrapped In Music Services. For individual website
        and app users, this Privacy Policy explains how Wrapped In Music may collect, use, and disclose information when you visit our website or when you use any website or app
        that uses Wrapped In Music Services.
      </p>

      <p class='description'>
        Publishers and Merchants and other clients may also have their own policies that govern how they collect, use, and share data. These policies may differ from Wrapped In
        Music’s policies described in this Privacy Policy. Please consult the privacy policies of the websites you visit and apps you use to become familiar with their privacy
        practices and to learn about any choices that these companies may offer with respect to their information practices. In addition, any website containing our Services may
        contain links to websites or content operated and maintained by third parties, over which we have no control. We encourage you to review the privacy policy of a third-party
        website before disclosing any information to the website.
      </p>

      <h2 class='section-title'>1. Information Collection and Use</h2>
      <p class='section-text'>
        Wrapped In Music collects data in a variety of ways - including through the use of log files, pixel tags, cookies, and/or similar technologies. Examples of the types of
        data that we collect are:
      </p>
      <ul class='data-list'>
        <li class='data-item'>Browser information (e.g. URL, browser type, ‘click through’ data);</li>
        <li class='data-item'>Ad reporting or delivery data (e.g. size/type of ad, ad impressions, location/format of ad, data about interactions with the ad);</li>
        <li class='data-item'>Device-type information (e.g. screen dimensions, device brand and model);</li>
        <li class='data-item'>Information about your activities on our website and Services.</li>
      </ul>
      <p class='section-text'>
        We may combine information that does not directly identify an individual with data collected from other sources and disclose the combined information to participating
        publishers, advertisers and ad networks so that they can determine whether to bid on ad inventory and in order to improve the relevance of the advertising presented to
        users. We also use the information we collect to host, operate, maintain, secure, and further develop and improve our Services, such as to keep track of advertising
        delivery and to measure the effectiveness of advertising delivered through our Services, and investigate compliance with Wrapped In Music’s policies and terms and
        conditions. Some of the third parties that we work with may contribute additional data to us directly, which we may combine with our own in order to help us provide a
        better service. We do not collect any information that could be used to directly identify an individual.
      </p>
      <p class='section-text'>
        Wrapped In Music does not engage in activities that require parental notice or consent under the Children’s Online Privacy Protection Act (COPPA). If you believe that
        Wrapped In Music has inadvertently collected information from a child under 13 that is subject to parental notice and consent under COPPA, please contact Wrapped In Music
        using the contact information below to request deletion of the information.
      </p>

      <h2 class='section-title'>2. Cookies and Other Similar Technologies</h2>
      <p class='section-text'>
        We use cookies (a small file containing a string of characters that uniquely identifies your Web browser), Web beacons (an electronic file placed within a Web site that
        monitors usage), pixels, etags, and similar technologies to operate and improve our website and Services, including for interest-based advertising as described below. Some
        of our Service Providers (defined below) may also use such technologies in connection with the services they perform on our behalf.
      </p>

      <h2 class='section-title'>3. Information Sharing</h2>
      <p class='section-text'>We will disclose contact and billing information to third parties only as described in this Privacy Policy:</p>
      <ul class='data-list'>
        <li class='data-item'>with your express permission;</li>
        <li class='data-item'>with our affiliates, which include entities controlling, controlled by, or under common control with Wrapped In Music;</li>
        <li class='data-item'>
          where we contract with third parties to provide certain services, such as advertising, analytics, data management services, web hosting, and web development ("Service
          Providers"). We ask Service Providers to confirm that their privacy and security practices are consistent with ours, we provide our Service Providers with only the
          information necessary for them to perform the services we request, and Service Providers are prohibited from using such information for purposes other than as specified
          by Wrapped In Music;
        </li>
        <li class='data-item'>
          in the event that Wrapped In Music is merged, sold, or in the event of a transfer of some or all of our assets (including in bankruptcy), or in the event of another
          corporate change, we may disclose or transfer information in connection with such transaction; and
        </li>
        <li class='data-item'>
          where we believe it is necessary to protect Wrapped In Music or our users; to enforce our terms or the legal rights of Wrapped In Music or others; or to comply with a
          request from governmental authorities, legal process, or other legal obligations.
        </li>
      </ul>
      <p class='section-text'>
        We may also share and disclose other information that we collect, including aggregate information, as we consider necessary to develop and provide our Services, including
        in the ways described above. The information that we share in this way would not be considered to personally identify an individual.
      </p>
      <p class='section-text'>
        Wrapped In Music may also be required to disclose information in response to lawful requests by public authorities, including to meet national security or law enforcement
        requirements.
      </p>

      <h2 class='section-title'>4. Interest-Based Advertising and Opting Out</h2>
      <p class='section-text'>
        Wrapped In Music adheres to the Digital Advertising Alliance (DAA) Self-Regulatory Principles in the US and to the European Digital Advertising Alliance (EDAA) Principles
        in the EU and the IAB Europe OBA Framework.
      </p>
      <p class='section-text'>
        The Wrapped In Music Ad Exchange uses cookies, Web beacons, pixels, etags, and similar technologies to give Publishers the possibility to offer, and Ad Exchange advertisers
        the ability to show, targeted ads on the device on which you are viewing this policy or a different device. These ads are more likely to be relevant to you because they are
        based on inferences drawn from location data, web viewing data collected across non-affiliated sites over time, and/or application use data collected across non-affiliated
        apps over time. This is called "interest-based advertising." In addition, certain third parties may collect data on the Wrapped In Music website and combine this data with
        information collected from other websites over time for purposes that include interest-based advertising.
      </p>

      <h2 class='section-title'>5. Opting Out for Cookie-Based Services</h2>
      <p class='section-text'>
        If you would like to learn more about this type of advertising, or would prefer to opt out of website interest-based advertising enabled by Wrapped In Music’s Ad Exchange,
        European Union residents may opt-out of this form of advertising by companies participating in the EDAA at{' '}
        <a href='http://www.youronlinechoices.com' target='_blank' class='link'>
          www.youronlinechoices.com
        </a>{' '}
        and all other users may visit{' '}
        <a href='http://www.aboutads.info/choices' target='_blank' class='link'>
          www.aboutads.info/choices
        </a>{' '}
        to opt out of this form of advertising by companies participating in the DAA self-regulatory program. Please note that in order for your opt-out choice to be effective
        using this tool, you must ensure that your browser is set to accept third-party cookies such as the Wrapped In Music opt-out cookie. Some browsers block third-party cookies
        by default, and you may need to change your browser settings to accept third-party cookies before opting out.
      </p>

      <h2 class='section-title'>6. Opting Out for Certain Non-Cookie Services (in applicable countries)</h2>
      <p class='section-text'>
        To help identify your browser and/or possible relationships between different browsers and devices, Wrapped In Music or our partners may use the local storage or cache in
        your browser. Using the browser cache or local storage helps Wrapped In Music or our partners deliver interest-based advertising to a browser without the use of third-party
        cookies. To opt out of Wrapped In Music’s collection of data through local storage or cache, you can clear the local storage and/or cache of your browser. You will need to
        repeat this process if you use more than one browser or device. To learn more about interest-based advertising, and to opt out of certain uses of your information for
        interest-based advertising by Wrapped In Music’s partners, please visit{' '}
        <a href='http://www.aboutads.info/choices' target='_blank' class='link'>
          www.aboutads.info/choices
        </a>{' '}
        and follow the instructions provided.
      </p>

      <h2 class='section-title'>7. Opting Out for Mobile Application Data</h2>
      <p class='section-text'>
        To opt out of Wrapped In Music’s collection, use, and transfer of data for interest-based advertising on mobile apps, you may download the DAA’s AppChoices application from
        the Android or iOS app store on your mobile device. Generated by Termzy.io. Users outside the United States may not have access to this application; instead, you can use
        "Limit Ad Tracking" in your iOS settings or "Opt out of interest-based ads" in your Android settings to limit Wrapped In Music’s collection of data for interest-based
        advertising.
      </p>

      <h2 class='section-title'>8. Opting Out for Location Data</h2>
      <p class='section-text'>
        You may opt out of our collection, use, and transfer of precise location data by using the location services controls in your mobile device’s settings.
      </p>

      <h2 class='section-title'>9. Effect of Opting Out</h2>
      <p class='section-text'>If you use a different device or browser, or erase cookies from your browser, you will need to renew your opt-out choice.</p>
      <p class='section-text'>
        If you opt out of Wrapped In Music’s practices, you may continue to receive interest-based advertising through other companies. Third-party advertisers and ad networks that
        participate in the Wrapped In Music Ad Exchange may also use their own cookies and other ad service technologies to display and track their ads. We do not control and are
        not responsible for such third-party advertisers’ and ad networks’ information practices or their use of cookies and other ad service technologies. To learn more about the
        practices of these companies, please read their privacy policies.
      </p>
      <p class='section-text'>
        Even if you opt-out, Wrapped In Music may continue to collect data for other purposes and you still will receive advertising from the Wrapped In Music Ad Exchange when you
        visit websites of a Publisher who uses our Services – but such advertising will not be targeted to you.
      </p>

      <h2 class='section-title'>10. Reviewing and Updating Information</h2>
      <p class='section-text'>
        Wrapped In Music takes reasonable steps to ensure that information is accurate, complete, current, and reliable for its intended use. For contact or billing information
        submitted through our website, you may review, correct, update, or change your information, request that we deactivate your account, or remove your information from our
        direct marketing efforts, at any time by e-mailing us at [Website Email].
      </p>

      <h2 class='section-title'>11. International Information Transfers</h2>
      <p class='section-text'>
        Please be aware that the information we collect, including contact and billing information, may be transferred to and maintained on servers or databases located outside
        your state, province, country, or other jurisdiction, where the privacy laws may not be as protective as those in your location. If you are located outside of the United
        States, please be advised that we process and store information in the United States and your consent to this Privacy Policy or use of Wrapped In Music Services represents
        your agreement to this processing.
      </p>

      <h2 class='section-title'>12. Security</h2>
      <p class='section-text'>
        Information that we collect is stored using procedures and practices reasonably designed to help protect information from unauthorized access, destruction, use,
        modification, or disclosure.
      </p>

      <h2 class='section-title'>13. Policy Updates</h2>
      <p class='section-text'>
        From time to time, we may change this Privacy Policy. If we decide to change this Privacy Policy, in whole or in part, we will inform you by posting the revised Privacy
        Policy on the Wrapped In Music website. Those changes will go into effect on the effective date disclosed in the revised Privacy Policy.
      </p>

      <h2 class='section-title'>14. Contact Us</h2>
      <p class='section-text'>If you have any questions or concerns regarding this Wrapped In Music Privacy Policy, please contact us by e-mailing us at [Website Email].</p>
    </div>
  );
};

export default Privacy;
