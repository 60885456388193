import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IMG } from '../api/constant';
import * as API from '../api/index';
import { MESSAGE } from '../schemas/Validation';
import { useState } from 'react';

const MusicDetails = ({ rootApiData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [songDetails, setSongDetails] = useState([]);
  const userId = localStorage.getItem('__userId');
  const header = localStorage.getItem('_tokenCode');
  const template = JSON.parse(localStorage.getItem('__template')) || {};
  const combinedId = localStorage.getItem('combinedId');

  const handleMusicOrder = async (data, isGuest = false, rediractToLink) => {
    const songAmount = songDetails?.song_amount || 0;
    const amount = template?.amount || songAmount;
    const mainId = location.state?.mainId || '';

    if (!userId && !isGuest) {
      MESSAGE('Please Register To Continue');
      localStorage.setItem('redirectToPurchase', true);
      localStorage.setItem('combinedId', mainId);

      const redirectPath = rediractToLink === 'login' ? '/login' : '/signup';
      return navigate(redirectPath);
    }

    try {
      const reqObj = { user: userId, combined: mainId || combinedId, amount };
      const apiFunction = data === '1' ? API.add_order : API.addToCart;
      const response = await apiFunction(reqObj, header);

      if (response?.data?.success === 1) {
        MESSAGE(response.data.msg, 1);

        if (data === '1') {
          navigate(response.data.url);
          if (userId) {
            localStorage.setItem('isLogin', true);
          }
        } else {
          rootApiData();
          localStorage.setItem('redirectToPurchase', false);
          localStorage.setItem('_cartItem', response.data.data[0]);
          navigate('/cart');
        }
      }
    } catch (error) {
      console.error('Error placing music order:', error);
    }
  };

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('__musicData'));
    window.scrollTo(0, 0);
    if (!data) {
      navigate('/login');
    } else {
      setSongDetails(data);
    }
  }, []);

  return (
    <>
      <div className='row justify-content-center mttop'>
        <div className='col-md-8'>
          <div class='ms_profile_box messPlacement'>
            <div class='ms_pro_form songDetais'>
              <div className='justify-content-center row justify-content-evenly'>
                <div className='col-md-4'>
                  <img src={IMG + songDetails?.image} className='w-100 musiDetImg' />
                </div>
                <div className='col-md-6 mt-5'>
                  <h2 className='musicheading'>{JSON.parse(localStorage.getItem('__selectedSongName'))}</h2>
                  <p>{songDetails?.decription}</p>
                  <strong className='musicPrice'>
                    Price : $ 0
                    {/* {JSON.parse(localStorage.getItem('__template'))?.amount === '' ? songDetails?.song_amount : JSON.parse(localStorage.getItem('__template'))?.amount} */}
                  </strong>
                  <span className='ownMusic'>
                    Record Message : <i class='bi bi-music-note-beamed'></i>
                  </span>
                  <div className='d-flex flex-wrap justify-content-between marger_top20'>
                    {!userId && (
                      <div className='marger_top20 pro-form-btn text-left text-start flex-fill'>
                        <button onClick={() => handleMusicOrder('1', true)} className='ms_btn m-0 text-white' style={{ padding: '0px 15px' }}>
                          Purchase as guest
                        </button>
                      </div>
                    )}
                    {!userId && (
                      <div className='marger_top20 pro-form-btn text-left text-start flex-fill'>
                        <button
                          onClick={() => handleMusicOrder('1', false, 'login')}
                          className='ms_btn m-0 text-white'
                          title={`${
                            !userId
                              ? 'Login to Existing Account to unlock additional features, such as saving your song message history, adding photos and text, and accessing your personalized song pickup page.'
                              : ''
                          }`}
                          style={{ padding: '0px 15px' }}
                        >
                          Login to Existing Account
                        </button>
                      </div>
                    )}
                    <div className='marger_top20 pro-form-btn text-left text-start flex-fill'>
                      <button
                        onClick={() => handleMusicOrder('1', false, 'Register')}
                        className='ms_btn m-0 text-white'
                        title={`${
                          !userId
                            ? 'Register for a free account to unlock additional features, such as saving your song message history, adding photos and text, and accessing your personalized song pickup page.'
                            : ''
                        }`}
                        style={{ padding: '0px 15px' }}
                      >
                        {userId ? 'Buy Now' : 'Register free account'}
                      </button>
                    </div>
                    {userId && (
                      <div className='marger_top20 pro-form-btn text-left text-start flex-fill ms-5'>
                        <button onClick={() => handleMusicOrder('2')} className='ms_btn m-0 text-white' style={{ padding: '0px 15px' }}>
                          <i className='bi bi-basket3-fill text-white'></i> Add To Cart
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MusicDetails;
