import React, { useEffect, useState } from 'react';
import InnerBanner from '../components/InnerBanner';
import * as API from '../api/index';
import { MESSAGE } from '../schemas/Validation';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const initialData = {
  name: '',
  email: '',
  phone: '',
  address: '',
  message: '',
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  phone: Yup.string().required('Phone number is required'),
  address: Yup.string(),
  message: Yup.string().required('Message is required'),
});

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const addContacty = async (values, { resetForm }) => {
    const header = localStorage.getItem('_tokenCode');
    try {
      const reqObj = {
        name: values.name,
        email: values.email,
        phone: values.phone,
        address: values.address,
        message: values.message,
      };
      const response = await API.contactUspage(reqObj, header);
      if (response.data.success === 1) {
        MESSAGE(response.data.msg, 1);
        resetForm();
      }
    } catch (error) {
      console.error('Error submitting the form', error);
    }
  };

  return (
    <div className='ms_profile_wrapper'>
      <div className='ms_profile_box contactUs'>
        <h1>Send Us a Message</h1>
        <Formik initialValues={initialData} validationSchema={validationSchema} onSubmit={addContacty}>
          {({ values, handleChange }) => (
            <Form className='ms_pro_form'>
              <div className='form-group position-relative'>
                <label>Name *</label>
                <Field type='text' placeholder='Name' className='form-control' name='name' />
                <ErrorMessage name='name' component='div' className='text-danger error-msg' />
              </div>
              <div className='form-group position-relative'>
                <label>Email *</label>
                <Field type='email' placeholder='Email' className='form-control' name='email' />
                <ErrorMessage name='email' component='div' className='text-danger error-msg' />
              </div>
              <div className='form-group position-relative'>
                <label>Phone Number *</label>
                <Field type='tel' placeholder='Phone Number' className='form-control' name='phone' />
                <ErrorMessage name='phone' component='div' className='text-danger error-msg' />
              </div>
              <div className='form-group position-relative'>
                <label>Address</label>
                <Field type='text' placeholder='Address' className='form-control' name='address' />
              </div>
              <div className='form-groupmk position-relative'>
                <label>Message *</label>
                <Field as='textarea' className='form-control' rows='3' id='qust' name='message' placeholder='Message...' />
                <ErrorMessage name='message' component='div' className='text-danger error-msg' />
              </div>
              <div className='pro-form-btn text-center marger_top15'>
                <button type='submit' className='ms_btn'>
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Contact;
