import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Base from '../components/Base';
import Song from '../pages/Song';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Category from '../pages/Category';
import WrapInMusic from '../pages/WrapInMusic';
import Contact from '../pages/Contact';
import Login from '../pages/Login';
import SignUp from '../pages/SignUp';
import MyAccount from '../pages/MyAccount';
import ForgotPassword from '../pages/ForgotPassword';
import CategoryDetails from '../pages/CategoryDetails';
import MessagePlacePage from '../pages/MessagePlacePage';
import MusicDetails from '../pages/MusicDetails';
import ThankYou from '../pages/ThankYou';

import * as API from '../api/index';
import SongDetails from '../pages/SongDetails';
import MusicTemplete from '../pages/MusicTemplete';
import Cart from '../components/Cart';
import SharableLink from '../pages/SharableLink';
import Privacy from '../components/Privacy';
import AuthGaurd from './AuthGaurd';
import UnAuthGaurd from './UnAuthGaurd';
const AppRouter = () => {
  const [cartItem, setCartItem] = useState([]);
  const [totalAmount, setTotalAmount] = useState('');
  const [trackData, setTrackData] = useState('');
  const [isLogin, setIsLogin] = useState(JSON.parse(localStorage.getItem('isLogin')) || false);
  const [isOpen, setIsOpen] = useState(false);

  const sidebarOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsLogin(JSON.parse(localStorage.getItem('isLogin')));
  }, []);

  const shouldDisplaySidebar = !['/sharable-link'].includes(window.location.pathname);

  const rootApiData = async () => {
    const header = localStorage.getItem('_tokenCode');
    try {
      const response = await API.aboutData(header);
      setTrackData(response?.data?.data);
      const cartresponse = await API.getCartList(localStorage.getItem('__userId'), header);
      setTotalAmount(cartresponse?.data?.total_amount);
      if (cartresponse?.data?.data.length > 0) {
        setCartItem(cartresponse?.data?.data);
      } else {
        setCartItem([]);
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  useEffect(() => {
    rootApiData();
  }, []);

  return (
    <>
      <Router>
        <div className='ms_main_wrapper'>
          {shouldDisplaySidebar && <Sidebar cartItem={cartItem} isOpen={isOpen} sidebarOpen={sidebarOpen} setIsLogin={setIsLogin} isLogin={isLogin} trackData={trackData} />}
          <div className={`${isOpen ? 'ms_content_wrapper padder_top80 ms_cont_left_marg' : 'ms_content_wrapper padder_top80'} ${!shouldDisplaySidebar ? 'shifted' : ''}`}>
            <Header isOpen={isOpen} shouldDisplaySidebar={shouldDisplaySidebar} />
            <Routes>
              <Route path='/' element={<Base />} />
              <Route path='/songs/category' element={<Song setIsLogin={setIsLogin} />} />
              <Route path='/songs/sub-category' element={<Category setIsLogin={setIsLogin} />} />
              <Route path='/wrapin-music' element={<WrapInMusic trackData={trackData} />} />
              <Route path='/contact-us' element={<Contact />} />
              <Route path='/song-list' element={<CategoryDetails />} />
              <Route path='/song-details' element={<MusicTemplete />} />
              <Route path='/message-placement' element={<MessagePlacePage />} />
              <Route path='/songs-details' element={<SongDetails />} />
              <Route path='/thankyou' element={<ThankYou setIsLogin={setIsLogin} />} />
              <Route path='/order-details' element={<MusicDetails cartItem={cartItem} rootApiData={rootApiData} />} />
              <Route path='/sharable-link' element={<SharableLink setIsLogin={setIsLogin} />} />
              <Route path='/cart' element={<Cart cartItem={cartItem} rootApiData={rootApiData} totalAmount={totalAmount} isLogin={isLogin} />} />

              <Route element={<AuthGaurd />}>
                <Route path='/my-account' element={<MyAccount setIsLogin={setIsLogin} />} />
                <Route path='/privacy' element={<Privacy setIsLogin={setIsLogin} />} />
              </Route>

              <Route element={<UnAuthGaurd />}>
                <Route path='/' element={<Login setCartItem={setCartItem} setIsLogin={setIsLogin} />}>
                  <Route path='/login' element={<Login setCartItem={setCartItem} setIsLogin={setIsLogin} />} />
                </Route>
                <Route path='/signup' element={<SignUp setIsLogin={setIsLogin} />} />
                <Route path='/forgot-password' element={<ForgotPassword setIsLogin={setIsLogin} />} />
              </Route>
            </Routes>
          </div>
        </div>
        {shouldDisplaySidebar && <Footer trackData={trackData} isOpen={isOpen} />}
      </Router>
    </>
  );
};

export default AppRouter;
